<template>
  <Page pageTitle="Projects"  customClass="is-projects mt-6">

     <!-- Row -->
      <Columns>
        <Column>
          Project detail
        </Column>
        <Column>
        </Column>
      </Columns>
      <!-- Row -->

    <template #rightbar>
      <h4>sidebar</h4>
    </template>

  </Page>
</template>

<script>

  import Page from '@/components/layout/Page.vue'
  // import Section from '../components/ds/Section.vue'
  import Columns from '@/components/ds/Columns.vue'
  import Column from '@/components/ds/Column.vue'

  export default {
    components: {
      Page,
      Columns,
      Column,
    },
  }
</script>

<style lang="scss" scoped>

</style>